.intro {
  width: 100%;
  overflow: hidden;
}
.home {
}
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: "200px";
  object-fit: cover; /* Cover the area of the parent container */
  z-index: -1; /* Place the video behind the content */
}

.content {
  z-index: 1; /* Ensure the content is above the background video */
  /* Other styling to position and style the text can go here */
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-image: "./images/background.png";
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
.product {
  background-color: rgba(
    255,
    255,
    255,
    0.3
  ); /* Semi-transparent white background */
  padding: 2rem;
}
.section-divider {
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
  margin: 2rem 0; /* Space above and below the line */
}
