.nav-link {
  font-size: clamp(0.5rem, 1.1vw, 1rem);
  color: #eaf3fb;
  font-style: normal;
  font-weight: 400;
  font-family: Microsoft YaHei, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
}
.active {
  font-weight: bold;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-color: #59bdff;
}
